.ended-conference {
  height: 100%;
  background: url('../../assets/bg-login.png') no-repeat center, #f5f5f5;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.card-ended-conference {
  padding: 70px 120px;
  background: #fff;
  border-radius: 7px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 100px 1px rgba(0, 0, 0, 0.2);

  .title {
    color: rgb(110, 110, 110);
    font-size: 28px;
    font-weight: 400;
  }

  .ended-conference-container {
    margin: 20px 0;
  }

  p {
    text-align: center;
    width: 230px;
    color: #999;
    font-size: 14px;
  }

  img {
    margin-top: 30px;
    height: 35px;
  }

  button {
    cursor: pointer;
    padding: 5px 15px 5px 15px;
    background-color: white;
    border: 1px #e8eaed solid;
    border-radius: 4px;
    box-sizing: border-box;
    color: #2f4191;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.25;
  }
}
