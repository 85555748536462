.meet-config-button {
  position: relative;
  margin: 0 7px;

  svg {
    color: #29434e;
  }

  .actions-button {
    background: #999191;
    position: absolute;
    right: -5px;
    top: 2px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    box-shadow: 0px 10px 12px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    transition: 0.2s;

    &:hover {
      background: #e4e4e4;
    }
  }

  .dropdown-mic {
    bottom: 62px;
    left: 20px;
    position: absolute;
    width: 320px;
    height: auto;
    background: #fff;
    border-radius: 7px;
    transition: 0.2s;
    box-shadow: 0px 10px 12px rgba(0, 0, 0, 0.2);

    padding: 10px 0;
    display: flex;
    flex-direction: column;
    // align-items: center;

    &::before {
      border-style: solid;
      border-color: #fff transparent;
      border-width: 8px 8px 0 8px;
      content: '';
      top: 100%;
      position: absolute;
      left: 35px;
      transform: translateX(-50%);
    }

    ul {
      list-style: none;

      .item-section {
        padding: 10px 5px;
        color: #003c8f;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-weight: bold;

        svg {
          color: #003c8f;
          margin-right: 5px;
          font-size: 18px;
        }
      }

      .audio-item {
        padding: 10px 20px;
        color: #999;
        font-size: 15px;
        cursor: pointer;
        transition: 0.2s;

        &:hover {
          background: #003c8f;
          color: #fff;
        }
      }
      .active {
        background: #003c8f;
        color: #fff;
      }
    }

    @media only screen and (max-width: 700px) {
      bottom: 62px;
      left: -100px;
      &::before {
        left: 155px !important;
      }
    }
  }
}
