/* .myCam {
  width: 30vw;
  top: auto;
  left: 10%;
  border-radius: 4%;
  height: auto;
  position: fixed;

  @media screen and (max-width: 1025px) {
    display: none;
  }
}

.background {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%;
  background: #121314;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    background: #58679e;

    svg {
      color: #fff;
    }
  }
}

.meet-info {
  position: absolute;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(0, 0, 0, 0.01);
  width: 100%;
  color: #fff;

  .logo {
    margin-top: 40px;
    height: 50px;
  }

  .meet-form {
    position: fixed;
    top: 29.5%;
    right: 21%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 1024px) {
      position: absolute;
      top: 50%;
      right: 50%;
      bottom: 50%;
      left: 50%;
    }
    &.notAvailableCam {
      position: absolute;
      top: 50%;
      right: 50%;
      bottom: 50%;
      left: 50%;
    }
  }

  .title {
    font-size: 24px;
    text-align: center;
  }

  .link-share {
    max-width: 300px;
    width: 300px;
    margin-top: 20px;
    padding: 10px;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.65);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    transition: 0.3s;
    p {
      width: 270px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:hover {
      background: rgba(0, 0, 0, 0.9);
    }
  }

  .links-action {
    margin: 50px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    a:-webkit-any-link {
      width: 250px;
      height: 70px;
      margin: 10px 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      background: #003c8f;
      color: #fff;
      font-size: 16px;
      border: none;
      border-radius: 7px;
      cursor: pointer;
      flex: 1;
      transition: 0.3s;

      svg {
        margin-right: 5px;
      }

      &:hover {
        background: #0d47a1;
      }
    }
  }

  form {
    margin: 30px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .meet-config {
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .icon-button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 5px;
      width: 50px;
      height: 50px;
      background: #3b3f42;
      border-radius: 50%;
      cursor: pointer;
      transition: 0.2s;

      &:hover {
        background: #2e3335;
      }

      svg {
        color: rgba(234, 226, 236, 255);
      }
    }

    .icon-button.no-config {
      background: #e9473e;
      svg {
        color: #e4e4e4;
      }

      &:hover {
        background: #e63127;
      }
    }
  }
}
 */

.myCam {
  // position: fixed;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  // min-width: 100%;
  // min-height: 100%;
  background: #29434e;
  width: 100vw;
  height: auto;
  position: fixed;
}

.background {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%;
  background: #29434e;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    background: #58679e;

    svg {
      color: #fff;
    }
  }
}

.meet-info {
  position: absolute;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  color: #fff;

  .logo {
    margin-top: 40px;
    height: 50px;
  }

  .meet-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .notAvailableCam {
    position: absolute;
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%;
  }

  .title {
    font-size: 24px;
    text-align: center;
    margin-top: 40px;
    width: 500px;
  }

  .link-share {
    max-width: 300px;
    width: 300px;
    margin-top: 20px;
    padding: 10px;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.65);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    transition: 0.3s;

    p {
      width: 270px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:hover {
      background: rgba(0, 0, 0, 0.9);
    }
  }

  .links-action {
    margin: 50px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    a:-webkit-any-link {
      width: 250px;
      height: 70px;
      margin: 10px 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      background: #003c8f;
      color: #fff;
      font-size: 16px;
      border: none;
      border-radius: 7px;
      cursor: pointer;
      flex: 1;
      transition: 0.3s;

      svg {
        margin-right: 5px;
      }

      &:hover {
        background: #0d47a1;
      }
    }
  }

  form {
    margin: 30px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .meet-config {
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .icon-button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 5px;
      width: 50px;
      height: 50px;

      background: #fff;
      border-radius: 50%;
      cursor: pointer;
      transition: 0.2s;

      &:hover {
        background: #e4e4e4;
      }
    }

    .icon-button.no-config {
      background: #ef476f;
      svg {
        color: #e4e4e4;
      }

      &:hover {
        background: #c43b5b;
      }
    }
  }
}
