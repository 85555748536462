@import '../../styles/mixins.scss';

@include keyframe(pulse) {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(239, 71, 111, 0.4);
    box-shadow: 0 0 0 0 rgba(239, 71, 111, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(239, 71, 111, 0);
    box-shadow: 0 0 0 10px rgba(239, 71, 111, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(239, 71, 111, 0);
    box-shadow: 0 0 0 0 rgba(239, 71, 111, 0);
  }
}

.video-actions-container-bottom {
  z-index: 1;
  opacity: 1;
  background-color: #202125;
  position: absolute;
  bottom: 0;
  padding: 0px 0px 0px 0px;
  width: 100%;
  height: 64px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: 0.2s;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .firstContainer {
    position: relative;
    margin-left: 6%;
    text-align: center;
    font-size: 12px;
  }
  .secondContainer {
    .buttonDropdown {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 20px;
      height: 20px;
      width: 20px;
      border-width: 0px;
      background-color: #4e4e4e;
      color: #fff;
      border-radius: 50%;
      cursor: pointer;
      z-index: 100;
      &:hover {
        background-color: #2d2d2d;
      }
      // &.camera {
      // }
      // &.microphone {
      // }
    }
  }
  .thirdContainer {
    margin-right: 20px;

    .button-call-hands-up-green-toggle {
      cursor: pointer;
      background: #3b3f42;
      box-shadow: 0px 10px 12px rgba(0, 0, 0, 0.2);
      border: none;
      border-radius: 50%;
      height: 45px;
      width: 45px;
      margin: 2px 6.5px 0px 0px;
      transition: 0.2s;
      svg {
        font-size: 20px;
      }

      &:hover {
        background-color: #2e3335;
      }
      .newMessagePing {
        background-color: red;
      }
    }
  }

  @media only screen and (max-width: 900px) {
    padding: 10px;
  }

  /* &.show {
    opacity: 1;
  } */

  .button-call-action {
    cursor: pointer;
    background: #3b3f42;
    border: none;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    height: 47px;
    width: 47px;
    transition: 0.2s;
    margin: -5px 6px;

    svg {
      font-size: 20px;
      color: rgba(234, 226, 236, 255);
    }

    &:hover {
      background: #2e3335;

      svg {
        color: rgba(234, 226, 236, 255);
      }
    }
    
    &.settings{
      cursor: none;
    }
  }

  .button-chat {
    position: relative;

    .chat-badge {
      position: absolute;
      width: 13px;
      height: 13px;
      background: #e9473e;
      border-radius: 50%;
      top: 0;
      right: 0;

      @include animation(0, 1.5s, pulse, infinite);
    }
  }

  .button-call-action.active {
    background: #e9473e;
    position: relative;
    top: 1.8px;

    svg {
      font-size: 24px;
      color: rgba(234, 226, 236, 255);
    }

    &:hover {
      background: #e63127;

      svg {
        color: rgba(234, 226, 236, 255);
      }
    }
  }

  .end-call {
    background: #e9473e;
    svg {
      color: rgba(234, 226, 236, 255);
    }

    &:hover {
      background: #e63127;

      svg {
        color: rgba(234, 226, 236, 255);
      }
    }
  }

  .dropdown-button {
    position: relative;
  }

  .dropdown-layouts {
    bottom: 67px;
    right: 12px;
    &.hands {
      right: 10px;
    }
    &.layouts {
      left: -100px;
    }
    position: absolute;
    width: 280px;
    height: auto;
    background: #3b3f42;
    border-radius: 7px;
    transition: 0.2s;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    // align-items: center;

    &::before {
      border-style: solid;
      border-color: #fff transparent;
      border-width: 8px 8px 0 8px;
      content: '';
      top: 100%;
      position: absolute;
      left: 124px;
      transform: translateX(-50%);
    }

    &.squad {
      &::before {
        left: 243px;
      }
    }

    ul {
      list-style: none;
      cursor: default;
      .layout-item {
        padding: 10px 20px;
        color: #999;
        font-size: 15px;
        cursor: pointer;
        transition: 0.2s;
        &.users {
          cursor: default;
          pointer-events: none;
        }
        &:hover {
          background: #003c8f;
          color: #fff;
        }
      }
      .active {
        background: #003c8f;
        color: #fff;
      }
    }

    @media only screen and (max-width: 700px) {
      bottom: 62px;
      left: -100px;
      &::before {
        left: 125px !important;
      }
    }
  }
}

.video-actions-container-top-right {
  opacity: 1;
  /* &.show {
    opacity: 1;
  } */
  position: absolute;
  width: 230px;
  height: 56px;
  top: -1px;
  right: 0px;
  transition: 0.2s;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  border-radius: 2%;
  .button-call-action {
    cursor: pointer;
    background: #3b3f42;
    border: none;
    border-radius: 50%;
    height: 42px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    width: 42px;
    transition: 0.2s;
    margin: 0px;
    svg {
      font-size: 20px;
      color: rgba(234, 226, 236, 255);
    }

    &:hover {
      svg {
        color: rgba(234, 226, 236, 255);
      }
    }
  }
  .button-call-action.active {
    background: #e9473e;

    svg {
      color: rgba(234, 226, 236, 255);
    }

    &:hover {
      background: #e63127;

      svg {
        color: rgba(234, 226, 236, 255);
      }
    }
  }
  @media only screen and (max-width: 700px) {
    width: 100%;
  }
  .dropdown-layouts {
    position: absolute;
    width: 231.5px;
    height: auto;
    background: rgb(248, 248, 248);
    border-radius: 4px;
    transition: 0.2s;
    box-shadow: 0px 10px 12px rgba(0, 0, 0, 0.2);
    cursor: default !important;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    // align-items: center;

    ul {
      list-style: none;
      .layout-item {
        padding: 10px 20px;
        color: #999;
        font-size: 15px;
        transition: 0.2s;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        &.hands-up {
          background: #003c8f;
          color: #fff;
          &:hover {
            background: #003c8fd8;
            color: #fff;
            cursor: pointer;
          }
        }

        .green {
          margin-left: 5px;
        }
      }

      .active {
        background: #003c8f;
        color: #fff;
      }
    }
  }
}

.d-none {
  display: none;
}

.divider {
  background-color: rgb(219, 218, 218);
  height: 80%;
  width: 1.2px;
  margin: 0px 10px 0px 10px;
}

.newMessagePing {
  background-color: red;
  width: 13px;
  height: 13px;
  border-radius: 100%;
  position: absolute;
  top: 7.5px;
  right: 88px;
}

.newMessageNumber {
  text-align: center;
  justify-content: center;
  color: rgb(219, 218, 218);
  font-size: 9px;
  margin-top: 2px;
  margin-left: 1px;
  padding: 0px;
  font-weight: bold;
}
