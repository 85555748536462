.meet-config-button {
  position: relative;
  margin: 0 7px;

  svg {
    color: #29434e;
  }

  .actions-button {
    background: #fff;
    position: absolute;
    right: -5px;
    top: 2px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    box-shadow: 0px 10px 12px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    transition: 0.2s;


    &:hover {
      background: #e4e4e4;
    }
  }

  .dropdown-videocam {
    bottom: 62px;
    right: -20px;
    position: absolute;
    width: 200px;
    height: auto;
    background: #fff;
    border-radius: 7px;
    transition: 0.2s;
    box-shadow: 0px 10px 12px rgba(0, 0, 0, 0.2);

    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &::before {
      border-style: solid;
      border-color: #fff transparent;
      border-width: 8px 8px 0 8px;
      content: '';
      top: 100%;
      position: absolute;
      right: 9px;
      transform: translateX(-50%);
    }

    .videocam-item {
      position: relative;
      cursor: pointer;

      .device-title {
        font-size: 14px;
        background: rgba(0,0,0, 0.4); 
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        // min-width: 100%; 
        // min-height: 100%;
        width: 180px;
        height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transition: 0.2s;

        &:hover {
          background: rgba(0,0,0, 0.6); 
        }
      }
    }
  }
}