.card-loading-conference {
  padding: 70px 120px;
  background: #fff;
  border-radius: 7px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 100px 1px rgba(0, 0, 0, 0.2);

  .title {
    color: rgb(110, 110, 110);
    font-size: 28px;
    font-weight: 400;
  }

  .loader-container {
    margin: 20px 0;
  }

  p {
    text-align: center;
    width: 230px;
    color: #999;
    font-size: 14px;
  }

  img {
    margin-top: 30px;
    height: 35px;
  }
}
