* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
}

html, body, #root {
  height: 100%;
  font-family: 'Public Sans', sans-serif;
}