@import '../../styles/mixins.scss';

@include keyframe(transform) {
  from {
    transform: translateX(420px);
  }
  to {
    transform: translateX(0px);
  }
}

.participants-container {
  z-index: 0;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;

  @include animationFadeIn(transform);
  transition: 0.2s;

  right: 0%;
  height: 91.6%;
  border-radius: 12px;
  width: 420px;

  background: #f2f6f9;

  .participants-container-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 25px;

    span {
      margin: 0;
      color: #60636a;
      font-size: 24px;
    }

    svg {
      font-size: 28px;
      color: rgb(173, 173, 173);
      cursor: pointer;
      transition: 0.2s;

      &:hover {
        color: rgb(136, 136, 136);
      }
    }
  }

  .participants-container-body {
    height: 100%;
    padding: 0 25px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      background-color: #f2f6f9;
      width: 16px;
      opacity: 0;
    }

    &::-webkit-scrollbar-track {
      background-color: #f2f6f9;
    }
    &::-webkit-scrollbar-track:hover {
      background-color: #f2f6f9;
    }

    /* scrollbar itself */
    &::-webkit-scrollbar-thumb {
      background-color: #babac0;
      border-radius: 16px;
      border: 5px solid #f2f6f9;
      opacity: 0;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: #a0a0a5;
      border: 4px solid #f4f4f4;
    }

    &::-webkit-scrollbar-track-piece {
      opacity: 0.2;
    }

    /* set button(top and bottom of the scrollbar) */
    &::-webkit-scrollbar-button {
      display: none;
    }
  }
}
