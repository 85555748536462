.users-layout-content-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  .users-layout-content-info {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .users-layout-align-mic-box {
      position: relative;
      bottom: 5px;
    }
    .users-layout-align-handsup-box {
      position: relative;
      bottom: 100px;
    }
    .icon {
      position: absolute;
      &.on {
        color: black;
      }
      &.off {
        color: #e9473e;
      }
      &.talking {
        color: rgb(22, 170, 22);
        &.onScreenShare {
          color: #ffd700;
        }
      }
    }
    .hands-up {
      position: absolute;
      color: rgb(22, 170, 22);
      &.onScreenShare {
        color: #ffd700;
      }
    }
  }

  .users-layout-content-name {
    font-size: 10px;
    width: 70px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: rgba(234, 226, 236, 255);
    padding-top: 10px;
  }

  .users-layout-content-user {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    max-width: 80px;
    height: 80px;
    max-height: 80px;
    border-radius: 50%;
    background-color: #202125;
    color: rgba(234, 226, 236, 255);
    margin: 10px 12px 5px 12px;

    &.onScreenShare {
      border: solid;
      border-width: 1.9px;
      border-color: #ffbf00;
    }
    &.on {
      border: solid;
      border-width: 2.2px;
      border-color: rgb(22, 170, 22);
      &.onScreenShare {
        border-color: #ffbf00;
      }
    }
  }
}
